<template>
	<form @submit.prevent="handleSubmit(!v$.$invalid)" class="p-grid">
		<Toast />
		<ConfirmDialog></ConfirmDialog>
		
		<div class="grid p-fluid stickBar">
			<div class="col-12">
				<div class="card card-w-title" id="stickBar">
					<Menubar :model="nestedMenuitems">
						<template #end>
							<div class="flex justify-content-end flex-wrap card-container green-container">
								<div class="flex align-items-center justify-content-center font-bold text-gray-500 text-xl m-2">
									OTOMASYON PROJESİ TEKLİF
								</div>
								<div class="flex align-items-center justify-content-center m-2">
									<Button class="p-button-rounded p-button-danger mr-0 mb-0" v-if="$router.options.history.state.back != null" icon="pi pi-times" @click="$router.go(-1)"/>
								</div>
							</div>
						</template>
					</Menubar>
				</div>
			</div>
		</div>

		<div class="grid">
			<div class="col-12">
				<div class="card">
					<EntityHeader label="OTOMASYON PROJESİ TEKLİF" :entityName="ENTITY_NAME"></EntityHeader>
					<div class="grid">
						<div class="col-3">
							<div class="field p-fluid">
								<EntityLookup id="hazirlayan" v-model="mainData.bm_teklifihazirlayanId" ref="entity_hazirlayan" :disabled="isDevreDisi"
									label="Hazırlayan" entityName="bm_teknikkullanicilar" nameField="bm_name" :state="true" :required="true"
									@itemSelected="hazirlayanSelected = $event" @itemCleared="hazirlayanSelected = null">
								</EntityLookup>
								<span v-if="v$.mainData.bm_teklifihazirlayanId.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.bm_teklifihazirlayanId.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-2">
							<div class="field p-fluid">
								<label for="bm_teklifhazirlanmatarihi">Hazırlanma Tarihi <span style="color:red">*</span></label>
								<Calendar showButtonBar id="bm_teklifhazirlanmatarihi" v-model="mainData.bm_teklifhazirlanmatarihi" autocomplete="off" :showIcon="true"  :disabled="isDevreDisi"/>
								<span v-if="v$.mainData.bm_teklifhazirlanmatarihi.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.bm_teklifhazirlanmatarihi.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-2">
							<div class="field p-fluid">
								<label for="bm_teklifgecerlilikgunu">Geçerlilik Günü</label>
								<Dropdown id="bm_teklifgecerlilikgunu" v-model="mainData.bm_teklifgecerlilikgunu" :options="SM_bm_teklifgecerlilikgunu" optionLabel="Value" optionValue="AttributeValue" placeholder="Geçerlilik Günü" :showClear="true" @change="changeGecerlilikGunu" />
							</div>
						</div>
						
						<div class="col-2">
							<div class="field p-fluid">
								<label for="bm_teklifgecerliliktarihi">Geçerlilik Tarihi <span style="color:red">*</span></label>
								<Calendar showButtonBar id="bm_teklifgecerliliktarihi" v-model="mainData.bm_teklifgecerliliktarihi" autocomplete="off" :showIcon="true" />
								<span v-if="v$.mainData.bm_teklifgecerliliktarihi.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.bm_teklifgecerliliktarihi.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="bm_teklifdurumu">Teklif Durumu <span style="color:red">*</span></label>
								<Dropdown id="bm_teklifdurumu" v-model="mainData.bm_teklifdurumu" :options="SM_bm_teklifdurumu" optionLabel="Value" optionValue="AttributeValue" placeholder="Teklif Durumu" :showClear="true" />
								<span v-if="v$.mainData.bm_teklifdurumu.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.bm_teklifdurumu.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="bm_indirimoncesitutar">İndirim Öncesi Tutar <span style="color:red">*</span></label>
								<InputNumber id="bm_indirimoncesitutar" v-model="mainData.bm_indirimoncesitutar" mode="decimal" locale="tr-TR" :minFractionDigits="2" @input="OnChangeIndirimOncesiTutar" />
								<span v-if="v$.mainData.bm_indirimoncesitutar.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.bm_indirimoncesitutar.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="bm_indirimtutari">İndirim Tutarı: <strong>({{ indirimOraniHesap }}%)</strong> <span style="color:red">*</span></label>
								<InputNumber id="bm_indirimtutari" v-model="mainData.bm_indirimtutari" mode="decimal" locale="tr-TR" :minFractionDigits="2" @input="OnChangeIndirimTutari" />
								<span v-if="v$.mainData.bm_indirimtutari.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.bm_indirimtutari.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="odemeTutari">Teklif Tutarı <span style="color:red">*</span></label>
								<InputNumber id="odemeTutari" v-model="mainData.bm_tekliftutari" mode="decimal" locale="tr-TR" :minFractionDigits="2" :disabled="true" />
								<span v-if="v$.mainData.bm_tekliftutari.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.bm_tekliftutari.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<EntityLookup id="bm_parabirimi" v-model="mainData.bm_parabirimiName" ref="entity_bm_parabirimi"
									label="Para Birimi" entityName="transactioncurrency" nameField="isocurrencycode" :state="true" :required="true"
									@itemSelected="bm_parabirimiSelected = $event" @itemCleared="bm_parabirimiSelected = null">
								</EntityLookup>
								<span v-if="v$.mainData.bm_parabirimiId.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.bm_parabirimiId.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>

						<div class="col-12">
							<div class="field p-fluid">
								<label for="ozelnot">Özel Not</label>
								<Textarea id="ozelnot" rows="4" :autoResize="false" v-model="mainData.bm_ozelnot" :disabled="isDevreDisi" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<Annotation objectTypeCode="10046" logicalName="bm_otomasyonteklif" :objectId="entityId"></Annotation>

	</form>
</template>

<script>
import CrmService from "../service/CrmService";
import { useVuelidate } from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";

export default {
	setup: () => ({ v$: useVuelidate() }),
	data() {
		return {
			crmService: null,
			submitted: false,
			OBJECT_TYPE_CODE: 10046,
			ENTITY_NAME: 'bm_otomasyonteklif',
			ENTITY_SUBJECT: 'Otomasyon Projesi',
			mainData: {},

			nestedMenuitems: [
				{
					label:'Kaydet',
					icon:'pi pi-fw pi-save',
					command: () => { this.OnSave(false); },
				},
				{
					label:'Kaydet & Kapat',
					icon:'pi pi-fw pi-save',
					command: () => { this.OnSave(true); },
				},
				{
                    label: "Sil",
                    icon: "pi pi-fw pi-trash",
                    command: () => { this.OnDelete(); },
					visible: () => this.isDeleted
                },
				{
					label:'Devre Dışı Bırak',
					icon:'pi pi-fw pi-check-circle',
					visible: () => !this.isDevreDisi,
					command: () => { this.OnDevreDisiBirak(); },
				},
				{
					label: 'Yeniden ETKİNLEŞTİR',
					icon: 'pi pi-fw pi-history',
					visible: () => this.isDevreDisi,
					command: () => { this.OnYenidenEtkinlestirItem(); },
                },
				{
					label:'Satışa Çevir',
					icon:'pi pi-fw pi-money-bill',
					visible: () => !this.isDevreDisi,
					command: () => { this.OnSatisaCevir(); },
				},
				{
					label:'Teklif İptal Edildi',
					icon:'pi pi-lock',
					visible: () => !this.isDevreDisi,
					command: () => { this.OnTeklifIptal(); },
				},
			],
		}
	},
	async created() {
		this.crmService = new CrmService();
		console.log('created');

		window.addEventListener('scroll', this.handleScroll);

		this.OnLoad();
    },
	computed: {
		entityId() {
			debugger;
			return this.$route.params.id;
		},
		otomasyonProjesiId() {
			debugger;
			return this.$route.params.id;
		},
		indirimOraniHesap(){
			if (this.mainData["bm_indirimtutari"] == 0) {
				return 0;
			}
			else {
				const oran = (this.mainData["bm_indirimtutari"] / this.mainData["bm_indirimoncesitutar"]) * 100;
				return oran.toFixed(2);
			}
		},
		profileData(){
			return this.$store.getters.getProfile;
		},
		isDevreDisi: function() {
			if (this.isYazmaYetkisiVarmi == false) {
				return true;
			}

			if (this.mainData["statecode"]) {
				if (this.mainData["statecode"] == 1) {
					return true;
				}
			}

			return false;
		},
		isYazmaYetkisiVarmi: function() {
			if (this.profileData) {
				if (this.profileData.moduller) {
					const filtered = this.profileData.moduller.filter(x => x.name == this.ENTITY_SUBJECT);
					if (filtered.length > 0) {
						return filtered[0].isWrite == true;
					}
				}
			}

			return false;
		},
		isDeleted: function() {
			if (this.profileData) {
				if (this.profileData.moduller) {
					const filtered = this.profileData.moduller.filter(x => x.name == 'Otomasyon Projesi');
					if (filtered.length > 0) {
						return filtered[0].isDelete == true;
					}
				}
			}

			return false;
		},
		SM_bm_teklifdurumu: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_teklifdurumu');
		},
		SM_bm_teklifgecerlilikgunu: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_teklifgecerlilikgunu');
		},
		hazirlayanSelected: {
			get: function () {
				if (this.mainData["bm_teklifihazirlayanId"]) {
					return { "Value": this.mainData["bm_teklifihazirlayanId"], "Name": this.mainData["bm_teklifihazirlayanName"] }
				} else{ return null; }
			},
			set: function(newValue) {
				if (newValue == null) {
					this.mainData["bm_teklifihazirlayanId"] = null;
					this.mainData["bm_teklifihazirlayanName"] = null;
				}
				else {
					this.mainData["bm_teklifihazirlayanId"] = newValue.Value;
					this.mainData["bm_teklifihazirlayanName"] = newValue.Name;
				}
			}
		},
		bm_parabirimiSelected: {
			get: function () {
				if (this.mainData["bm_parabirimiId"]) {
					return { "Value": this.mainData["bm_parabirimiId"], "Name": this.mainData["bm_parabirimiName"] }
				} else{ return null; }
			},
			set: function(newValue) {
				if (newValue == null) {
					this.mainData["bm_parabirimiId"] = null;
					this.mainData["bm_parabirimiName"] = null;
				}
				else {
					this.mainData["bm_parabirimiId"] = newValue.Value;
					this.mainData["bm_parabirimiName"] = newValue.Name;
				}
			}
		},
		
	},
	methods: {
		async OnLoad(){
			let loader = this.$loading.show({
				container: this.$refs.smsContainer
			});

			debugger;
			if (this.entityId != null) {
				try {
					debugger;

					let data = await this.crmService.getOtomasyonTeklifiById(this.entityId);
					if (data.yetkiVarmi == false) {
						this.$router.replace({ name: 'accessdenied' });
					}
					
					if (data.jsonData.length > 0) {
						this.mainData = data.jsonData[0];

						setTimeout(() => {
							if (this.mainData["bm_teklifihazirlayanId"]) {
								this.$refs.entity_hazirlayan.setDefaultData({ "Value": this.mainData["bm_teklifihazirlayanId"], "Name": this.mainData["bm_teklifihazirlayanName"] });
							}

							if (this.mainData["bm_parabirimiId"]) {
								this.$refs.entity_bm_parabirimi.setDefaultData({ "Value": this.mainData["bm_parabirimiId"], "Name": this.mainData["bm_parabirimiName"] });
							}
						}, 1000);

						if (this.mainData["bm_teklifhazirlanmatarihi"]) {
							this.mainData["bm_teklifhazirlanmatarihi"] = new Date(this.mainData.bm_teklifhazirlanmatarihi);
						}

						if (this.mainData["bm_teklifgecerliliktarihi"]) {
							this.mainData["bm_teklifgecerliliktarihi"] = new Date(this.mainData.bm_teklifgecerliliktarihi);
						}
					}
				} catch (error) {
					console.log(error);
					//this.$router.push('/');
				}
				finally {
					loader.hide();
				}
			}
			else {
				//this.$router.push('/');
				loader.hide();
			}
		},
		async OnSave(kapat) {
			debugger;
			
			this.submitted = true;
			this.v$.$touch();

			if (this.v$.$invalid) {
				this.v$.$errors.forEach(element => {
					this.$toast.add({ severity: 'error', summary: 'Eksik alanlar var.', detail: element.$message, life: 3500 });
				});
			} else {
				try {
					this.mainData["entityId"] = this.entityId;
					const response = await this.crmService.getOtomasyonTeklifiUpdate(this.mainData);
					if (response) {
						if (response.hata == true) {
							this.$toast.add({severity:'error', summary: 'Kaydedilemedi', detail: response.hataAciklamasi, life: 5000});
						}else {
							this.$toast.add({severity:'success', summary: 'Bilgi', detail:'Başarıyla Kaydedildi', life: 3000});
							if (window.opener) {
								window.opener.window.postMessage({'tag': 'refresh'}, window.location.origin);
							}
							
							if (kapat) {
								setTimeout(() => {
									window.close();
								}, 1000);
							}
						}
					}
				} catch (error) {
					this.$toast.add({severity:'error', summary: 'Eksik alanlar var.', detail: error.message, life: 3500});
				}
			}
		},
		OnChangeIndirimOncesiTutar(event) {
			const indirimOncesiTutar = event.value;
			this.mainData["bm_tekliftutari"] = indirimOncesiTutar - this.mainData["bm_indirimtutari"];
			debugger;
		},
		OnChangeIndirimTutari(event) {
			const indirimOncesiTutar = event.value;
			this.mainData["bm_tekliftutari"] = this.mainData["bm_indirimoncesitutar"] - indirimOncesiTutar;
			debugger;
		},
		async OnDelete(){
            this.deleteItem(this.ENTITY_NAME, this.entityId);
        },
		async OnSatisaCevir() {
			debugger;
			
			let loader = this.$loading.show({
				container: this.$refs.smsContainer
			});

			try {
				const response = await this.crmService.getOtomasyonTeklifiSatisaCevir(this.entityId);
				if (response) {
					if (response.hata == true) {
						this.$toast.add({severity:'error', summary: 'Kaydedilemedi', detail: response.hataAciklamasi, life: 5000});
					}else {
						this.$toast.add({severity:'success', summary: 'Bilgi', detail:'Başarıyla Çevrildi', life: 3000});
						if (window.opener) {
							window.opener.window.postMessage({'tag': 'refresh'}, window.location.origin);
						}

						setTimeout(() => {
							this.$router.push({ name: "bm_otomasyonsatis", params: { id: response.olusturulanId } });
						}, 2000);
					}
				}
			} catch (error) {
				loader.hide();
				this.$toast.add({severity:'error', summary: 'Bir Hata Oluştu.', detail: error.message, life: 3500});
			}
			finally {
				loader.hide();
			}
		},
		async OnTeklifIptal() {
			debugger;
			
			let loader = this.$loading.show({
				container: this.$refs.smsContainer
			});

			try {
				const response = await this.crmService.getOtomasyonTeklifiIptal(this.entityId);
				if (response) {
					if (response.hata == true) {
						this.$toast.add({severity:'error', summary: 'Kaydedilemedi', detail: response.hataAciklamasi, life: 5000});
					}else {
						this.$toast.add({severity:'success', summary: 'Bilgi', detail:'Başarıyla Çevrildi', life: 3000});
						if (window.opener) {
							window.opener.window.postMessage({'tag': 'refresh'}, window.location.origin);
						}
						
						location.reload();
					}
				}
			} catch (error) {
				loader.hide();
				this.$toast.add({severity:'error', summary: 'Bir Hata Oluştu.', detail: error.message, life: 3500});
			}
			finally {
				loader.hide();
			}
		},
		OnDevreDisiBirak() {
			const postData = {
				logicalName: this.ENTITY_NAME,
				entiyId: this.entityId,
				stateCode: 1,
				statusCode: 2
			};

			this.devreDisiItem(postData);
		},
		OnYenidenEtkinlestirItem() {
			const postData = {
				logicalName: this.ENTITY_NAME,
				entiyId: this.entityId,
				stateCode: 0,
				statusCode: 1
			};

			this.yenidenEtkinlestirItem(postData);
		},
		handleScroll() {
			const el = document.getElementById('stickBar');

			if (window.scrollY > 50) {
				el.style.boxShadow = '0px 2px 1px rgba(0, 0, 0, 0.09), 0px 4px 2px rgba(0, 0, 0, 0.09), 0px 8px 4px rgba(0, 0, 0, 0.09), 0px 16px 8px rgba(0, 0, 0, 0.09), 0px 32px 16px rgba(0, 0, 0, 0.09)';
			}
			else {
				el.style.boxShadow = '';
			}
		},
		changeGecerlilikGunu(event){
			debugger;
			if (event.value) {
				let teklifHazirlanmaTarihi = new Date(this.mainData.bm_teklifhazirlanmatarihi);

				const gecerlilikGunu = event.value;
				let yeniTarih = teklifHazirlanmaTarihi;
				yeniTarih.setDate(teklifHazirlanmaTarihi.getDate() + gecerlilikGunu);
				this.mainData["bm_teklifgecerliliktarihi"] = yeniTarih;
			}
			else {
				this.mainData.bm_teklifgecerliliktarihi = null;
			}
		}
	},
	watch: {
		profileData(val) {
			if (val) {
				if (val.moduller) {
					const filtered = val.moduller.filter(x => x.name == "Otomasyon Projesi");
					if (filtered.length == 0) {
						this.$router.replace({ name: 'accessdenied' });
					}
				}
			}
		}
	},
	validations() {
		return {
			mainData: {
				bm_teklifihazirlayanId: {
					required : helpers.withMessage('Hazırlayan alanı dolu olmalıdır!', required),
				},
				bm_teklifhazirlanmatarihi: {
					required : helpers.withMessage('Hazırlanma Tarihi alanı dolu olmalıdır!', required),
				},
				bm_teklifdurumu: {
					required : helpers.withMessage('Teklif Durumu alanı dolu olmalıdır!', required),
				},
				bm_indirimoncesitutar: {
					required : helpers.withMessage('İndirim Öncesi Tutarı alanı dolu olmalıdır!', required),
				},
				bm_indirimtutari: {
					required : helpers.withMessage('İndirim Tutarı alanı dolu olmalıdır!', required),
				},
				bm_tekliftutari: {
					required : helpers.withMessage('Teklif Tutarı alanı dolu olmalıdır!', required),
				},
				bm_parabirimiId: {
					required : helpers.withMessage('Para Birimi alanı dolu olmalıdır!', required),
				},
				bm_teklifgecerliliktarihi: {
					required : helpers.withMessage('Geçerlilik Tarihi alanı dolu olmalıdır!', required),
				},
			},
		}
	}
}
</script>

<style lang="scss" scoped>
.stickBar {
	position: sticky;
    top: 6.2rem;
    z-index: 10;
}
</style>
